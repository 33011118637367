@font-face {
  font-family: 'PT Sans Regular';
  src: local('PTSans-Regular'), url('./assets/fonts/PTSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'PT Sans Italic';
  font-style: italic;
  font-weight: 400;
  src: local('PTSans-Italic'), url('./assets/fonts/PTSans-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'PT Sans Bold';
  font-style: normal;
  font-weight: 700;
  src: local('PTSans-Bold'), url('./assets/fonts/PTSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 700;
  src: local('PTSans-BoldItalic'), url('./assets/fonts/PTSans-BoldItalic.ttf') format('truetype');
}


body {
  font-family: 'PT Sans Regular';
  margin: 0;
}
